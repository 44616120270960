<template>
	<div :class="$style.wrapper">
		<h4>
			{{ type }}
			<span :class="$style.count">{{ meals.length }}</span>
		</h4>
		<div :class="$style.table">
			<div>Фото</div>
			<div>Наименование товара</div>
			<div>Вес</div>
			<div>Ккал</div>
			<div>Белки</div>
			<div>Жиры</div>
			<div>Углеводы</div>
			<div>Цена</div>
			<div>Ред.</div>
		</div>
		<div
			v-for="meal, key in meals"
			:class="$style.row"
			:key="meal.type+key"
		>
			<img :src="meal.picture"/>
			<div>
				<div>{{ meal.name }}</div>
				<div :class="$style.desc">{{ meal.sostav }}</div>
			</div>
			<div>{{ meal.weight }}</div>
			<div>{{ meal.kkal }}</div>
			<div>{{ meal.belki }}</div>
			<div>{{ meal.jiri }}</div>
			<div>{{ meal.uglevodi }}</div>
			<div :class="$style.price">{{ meal.price + ' ₽' }}</div>
			<div
				:class="$style.icon"
				@click="$emit('changeMeal', {id: key, type: meal.type})"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['type', 'meals'],
	data()
	{
		return {}
	},
	components: {},
	computed: {},
	methods: {},
}
</script>

<style
	lang="scss"
	module
>
$lightgray: #F0F0F0;
.wrapper
{
	display: grid;
	gap: 12px;
	padding: 0 10px;
}

h4
{
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 0;
}

.table
{
	display: grid;
	grid-template-columns: 56px 1fr 60px 60px 60px 60px 105px 115px 50px;
	gap: 24px;
	background: #F8F8F8;
	border-radius: 4px;
	color: $greyText;
	padding: 4px 20px 4px 16px;
	font-size: 12px;
	margin: 0 4px 0 -2px;
	@media (max-width: 1200px)
	{
		grid-template-columns: 56px 1fr 50px 50px 50px 50px 95px 105px 40px;
		gap: 16px;
	}
}

.row
{
	display: grid;
	grid-template-columns: 56px 1fr 60px 60px 60px 60px 95px 115px 60px;
	gap: 24px;
	font-family: 'Roboto', 'PT Sans', sans-serif;
	height: 76px;
	border: 2px solid white;
	border-radius: 8px;
	padding: 8px 16px;
	font-size: 13px;
	font-weight: bold;
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);

	&.current
	{
		border: 2px solid $green;
	}

	@media (max-width: 1200px)
	{
		grid-template-columns: 56px 1fr 50px 50px 50px 50px 85px 105px 50px;
		gap: 16px;
	}

	div
	{
		display: grid;
		align-content: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img
	{
		height: 56px;
		border: 1px solid $lightgray;
		object-fit: contain;
	}

	.desc
	{
		font-size: 11px;
		color: $greyLightText;
	}

	.price
	{
		font-size: 16px;
	}

	.icon
	{
		width: 40px;
		height: 40px;
		background-image: url('~@/assets/img/changeIcon.svg');
		background-size: cover;
		border: none;
		box-shadow: none;
		margin-top: 7px;
		cursor: pointer;
	}
}

.count
{
	color: $green;
}
</style>