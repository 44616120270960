<template>
	<div
		:class="$s.back"
		v-if="show"
		@click.self="closePopup"
	>
		<div
			:class="$s.closeIcon"
			@click="$emit('closePopup')"
		></div>
		<div
			:class="$s.popup"
			:style="loading ? 'opacity: 0.5;' : ''"
		>
			<div :class="$s.header">
				Редактировать блюдо
			</div>
			<div :class="$s.photo">
				<div :class="$s.label">Фото блюда</div>
				<img :src="data.pictureDetail"/>
			</div>
			<div :class="$s.name">
				<div :class="$s.label">Название блюда</div>
				<input v-model="form.name"/>
			</div>
			<div :class="$s.sostav">
				<div :class="$s.label">Состав блюда, ингридиенты через запятую</div>
				<textarea v-model="form.sostav"/>
			</div>
			<div :class="$s.inputs">
				<div>
					<div :class="$s.label">Вес, гр</div>
					<input v-model="form.weight"/>
				</div>
				<div>
					<div :class="$s.label">Калорийность, Ккал</div>
					<input v-model="form.kkal"/>
				</div>
				<div>
					<div :class="$s.label">Белки, на 100 гр</div>
					<input v-model="form.belki"/>
				</div>
				<div>
					<div :class="$s.label">Жиры, на 100 гр</div>
					<input v-model="form.jiri"/>
				</div>
				<div>
					<div :class="$s.label">Углеводы, на 100 гр</div>
					<input v-model="form.uglevodi"/>
				</div>
				<div>
					<div :class="$s.label">Цена руб</div>
					<input v-model="form.price"/>
				</div>
			</div>
			<button
				:class="$s.button"
				@click="updateMeal"
			>Сохранить
			</button>
			<div
				:class="$s.error"
				v-if="errorMsg"
			>{{ errorMsg }}
			</div>
		</div>
	</div>
</template>

<script>
import {mapMutations, mapState, mapGetters, mapActions} from "vuex";
import axios from "axios";
import qs from "qs";

export default {
	props: ['show', 'data'],
	data()
	{
		return {
			form: {},
			errorMsg: false,
			loading: false,
		}
	},
	computed: {},
	methods: {
		async updateMeal()
		{
			this.loading = true;
			let data = qs.stringify({
				meal: this.form,
			});
			let result = await axios.post('/lk/updateMeal/', data);
			this.loading = false;

			this.errorMsg = false;
			if (result.data.success)
			{
				this.$emit('changeMeal', this.form);
			} else
			{
				this.errorMsg = result.data.error;
			}
		},
	},
	watch: {
		data()
		{
			this.form = Object.assign({}, this.data);
		}
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.back
{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.5);
}

.popup
{
	border: 1px solid #F0F0F0;
	border-radius: 8px;
	position: absolute;
	top: 15%;
	left: 30%;
	background-color: white;
	padding: 48px;
	display: grid;
	gap: 16px 24px;
	grid:
                "header header header" 48px
                "photo name name" 72px
                "photo sostav sostav" 160px
                "inputs inputs inputs" 160px
                "button button button" 48px
            / 207px 207px 207px;
	@media (max-width: 1200px)
	{
		top: 50px;
		left: 15%;
	}

	.header
	{
		grid-area: header;
		font-weight: bold;
		font-size: 28px;
		text-align: center;
	}

	.photo
	{
		grid-area: photo;

		img
		{
			width: 207px;
			height: 224px;
			border: 1px solid #F0F0F0;
			border-radius: 8px;
			object-fit: contain;
		}
	}

	.name
	{
		grid-area: name;
	}

	.sostav
	{
		grid-area: sostav;
	}

	.inputs
	{
		grid-area: inputs;
		display: grid;
		grid-template-columns: repeat(3, 207px);
		gap: 16px 24px;
	}

	.button
	{
		grid-area: button;
		font-weight: bold;
		font-size: 18px;
		color: white;
		background: $green;
		border: none;
		border-radius: 4px;
	}

	.label
	{
		font-size: 12px;
		color: $greyLightText;
	}

	input, textarea
	{
		width: 100%;
		height: 48px;
		background-color: $greyBackground;
		border-radius: 4px;
		padding: 12px 24px;
		align-content: center;
		border: 1px solid $greyBorder;
		font-size: 16px;
	}

	textarea
	{
		height: 136px;
	}
}

.closeIcon
{
	background: url('~@/assets/img/close.svg');
	background-size: 20px;
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	top: calc(15% + 20px);
	left: calc(30% + 730px);
	z-index: 110;
	@media (max-width: 1200px)
	{
		top: 70px;
		left: calc(15% + 730px);
	}
}

.error
{
	color: red;
}

</style>