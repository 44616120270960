<template>
	<div
		:class="$style.mealTypesList"
		ref="scroll"
	>
		<button
			v-for="mealType in mealTypes"
			:data-group-btn="mealType"
			:class="{[$style.active]:group === mealType}"
			@click="setCurrentMealGroup(mealType)"
		>
			<div
				:class="$style.count"
				v-if="modeActive"
			>
				<span>{{ (mealType in activeMealCount) ? activeMealCount[mealType].all : 0 }}</span>
				/
				<span :class="{[$style.warning]:lunchProblem.any && !lunchProblem[mealType]}">
                        {{ (mealType in activeMealCount) ? activeMealCount[mealType].lunch : 0 }}
                    </span>
			</div>
			<div :class="[$style.picture, getClass(mealType)]"></div>
			<div :class="$style.type">{{ mealType }}</div>
		</button>
		<div :class="$style.end"></div>
	</div>
</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
	data()
	{
		return {
			group: false
		};
	},
	props: ['currentMealGroup', 'mealTypes', 'modeActive'],
	computed: {
		...mapGetters('lkmenu', ['activeMealCount', 'lunchProblem']),
		...mapState('lkmenu', ['intersectionProcess']),
	},
	methods: {
		setCurrentMealGroup(group)
		{
			if (!this.activeMealCount.hasOwnProperty(group))
				return;

			this.group = group;

			this.$emit('scrollToGroup', group);
			this.$emit('scrollToGroupBtn', group);
			this.$emit('setCurrentMealGroup', group);
		},

		getClass(type)
		{
			switch (type)
			{
				case('Супы'):
					return this.$style.sup;
				case('Гарниры'):
					return this.$style.garnir;
				case('Салаты'):
					return this.$style.salat;
				case('Горячие'):
					return this.$style.hot;
				case('Выпечки'):
					return this.$style.vipechka;
				case('Десерты'):
					return this.$style.desert;
				case('Напитки'):
					return this.$style.drink;
				case('Роллы'):
					return this.$style.rolls;
				case('Соусы'):
					return this.$style.sous;
				case('Суши и другое'):
					return this.$style.sushi;
				case('Суши'):
					return this.$style.sushi;
				case('Поке'):
					return this.$style.salat;
				case('Завтраки'):
					return this.$style.breakfast;
				case('Закуски'):
					return this.$style.snack;
				case('Шашлыки'):
					return this.$style.kebabs;
				case('Хинкали'):
					return this.$style.khinkali;
				case('Пицца'):
					return this.$style.pizza;
				case('Бургеры'):
					return this.$style.burgers;
			}
		}
	},
	watch: {
		async currentMealGroup(group)
		{

			if (this.intersectionProcess || group == this.group)
				return;

			this.group = group;

			this.$emit('scrollToGroupBtn', group);
		}
	},
	mounted()
	{
		this.group = this.currentMealGroup;
	}
}
</script>
<style
	lang="scss"
	module
>
.mealTypesList
{
	display: grid;
	grid-auto-flow: column dense;
	justify-content: start;
	gap: 10px;
	min-width: 0;
	overflow-x: auto;

	padding: 2px 8px 8px 8px;
	margin: -2px 0 -8px 0;


	&::-webkit-scrollbar
	{
		height: 0;
	}

	.end
	{
		width: 1px;
	}


	button
	{

		background-color: white;
		width: 80px;
		height: 72px;
		box-sizing: border-box;

		@media (max-height: 500px)
		{
			height: 60px;
		}
		border-radius: 8px;
		border: 1px solid $greyBorder;
		box-shadow: (0px 0px 2px rgba(0, 0, 0, 0.08)), (0px 4px 8px rgba(0, 0, 0, 0.08));
		position: relative;

		.count
		{
			position: absolute;
			top: 5px;
			right: 5px;
			font-size: 11px;
			border: 1px solid $greyBorder;
			background-color: $greyBackground;
			color: $greyText;
			box-sizing: border-box;
			border-radius: 14px;
			padding: 0px 3px;
			z-index: 1;

			.warning
			{
				color: #FD470E;
			}

		}

		.type
		{
			position: absolute;
			left: 0;
			bottom: 5px;
			width: 100%;
			font-size: 12px;
			line-height: 14px;
			color: $textColor;
			font-weight: 500;
			background-color: white;
		}

		.picture
		{
			height: 100%;
			width: 100%;
			/*position: absolute;*/
			/*top*/

			background-color: $green;
			mask-repeat: no-repeat;
			mask-position: center;

			&.sup
			{
				mask-image: url('~@/assets/img/sup.svg');
			}

			&.garnir
			{
				mask-image: url('~@/assets/img/garnir.svg');
			}

			&.salat
			{
				mask-image: url('~@/assets/img/salat.svg');
			}

			&.hot
			{
				mask-image: url('~@/assets/img/hot.svg');
			}

			&.vipechka
			{
				mask-image: url('~@/assets/img/vipechka.svg');
			}

			&.desert
			{
				mask-image: url('~@/assets/img/desert.svg');
			}

			&.drink
			{
				mask-image: url('~@/assets/img/drink.svg');
			}

			&.rolls
			{
				mask-image: url('~@/assets/img/rolls.svg');
			}

			&.sous
			{
				mask-image: url('~@/assets/img/sous.svg');
			}

			&.sushi
			{
				mask-image: url('~@/assets/img/sushi.svg');
			}

			&.poke
			{
			}

			&.breakfast
			{
				mask-image: url('~@/assets/img/breakfast.svg');
			}

			&.snack
			{
				mask-image: url('~@/assets/img/snack.svg');
			}

			&.kebabs
			{
				mask-image: url('~@/assets/img/kebabs.svg');
				mask-size: 32px 32px;
			}

			&.khinkali
			{
				mask-image: url('~@/assets/img/khinkali.svg');
				mask-size: 32px 32px;
			}

			&.pizza
			{
				mask-image: url('~@/assets/img/pizza.svg');
				mask-size: 32px 32px;
			}

			&.burgers
			{
				mask-image: url('~@/assets/img/burgers.svg');
				mask-size: 32px 32px;
			}
		}


		&.active
		{
			background-color: $green;

			.count
			{
				border: 1px solid white;
				background-color: white;
				color: $green;
			}

			.type
			{
				color: white;
				background-color: $green;
			}

			.picture
			{
				background-color: white;
			}
		}

	}


}


</style>
